import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  return `seats/venues/${url}`;
};

export const userMapsApi = createApi({
  reducerPath: 'userMaps',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_SEATS,
    prepareUrl,
  }),
  tagTypes: ['UserMaps'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getUserMaps: build.query({
      query: () => 'maps',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'UserMaps', id })),
              { type: 'UserMaps', id: 'LIST' },
            ]
          : [{ type: 'UserMaps', id: 'LIST' }],
    }),
    getUserMap: build.query({
      query: (id) => `maps/${id}`,
      providesTags: (result, error, id) => [{ type: 'UserMaps', id }],
    }),
  }),
});

export const { useGetUserMapQuery, useGetUserMapsQuery } = userMapsApi;
