import dayjs from 'dayjs';
import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  if (endpoint === 'getTicketTypesByEvent' || endpoint === 'getTickets') {
    return url;
  }

  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  return `ticket-types/events/${eventID}/${url}`;
};

export const ticketTypeApi = createApi({
  reducerPath: 'ticketType',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['TicketType'],
  endpoints: (build) => ({
    getTicketTypes: build.query({
      query: (data) =>
        data ? 'ticket-types?relationship=true' : 'ticket-types',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'TicketType', id })),
              { type: 'TicketType', id: 'LIST' },
            ]
          : [{ type: 'TicketType', id: 'LIST' }],
    }),
    getTicketTypesByEvent: build.query({
      query: (event) => `ticket-types/events/${event}/ticket-types`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'TicketType', id })),
              { type: 'TicketType', id: 'LIST' },
            ]
          : [{ type: 'TicketType', id: 'LIST' }],
    }),
    getTickets: build.mutation({
      query: (eventId) => ({
        url: `ticket-types/events/${eventId}/ticket-types`,
        method: 'GET',
      }),
    }),
    addTicketType: build.mutation({
      query: (body) => ({
        url: `ticket-types`,
        method: 'POST',
        body: {
          name: body.name,
          description: body.description,
          section: body.section,
          free: body.free,
          price: body.free ? 0 : +body.price,
          quantity: +body.quantity,
          max_per_sale: +body.max_per_sale,
          min_per_sale: +body.min_per_sale,
          start_date: dayjs(body.start_date).subtract(6, 'hour'),
          end_date: dayjs(body.end_date).subtract(6, 'hour'),
          label: body.label,
          status: body.status,
        },
      }),
      invalidatesTags: [{ type: 'TicketType', id: 'LIST' }],
    }),
    addTicketTypeWithOutMap: build.mutation({
      query: (body) => ({
        url: `ticket-types`,
        method: 'POST',
        body: {
          name: body.name,
          description: body.description,
          free: body.free,
          price: body.free ? 0 : +body.price,
          quantity: +body.quantity,
          max_per_sale: +body.max_per_sale,
          min_per_sale: +body.min_per_sale,
          start_date: dayjs(body.start_date).subtract(6, 'hour'),
          end_date: dayjs(body.end_date).subtract(6, 'hour'),
          label: body.label,
          status: body.status || 'active',
        },
      }),
      invalidatesTags: [{ type: 'TicketType', id: 'LIST' }],
    }),
    getTicketType: build.query({
      query: (id) => `ticket-types/${id}`,
      providesTags: (result, error, id) => [{ type: 'TicketType', id }],
    }),
    updateTicketType: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `ticket-types/${id}`,
        method: 'PUT',
        body: {
          name: patch.name,
          description: patch.description,
          section: patch.section,
          free: patch.free,
          price: patch.free ? 0 : +patch.price,
          quantity: +patch.quantity,
          max_per_sale: +patch.max_per_sale,
          min_per_sale: +patch.min_per_sale,
          start_date: patch.start_date,
          end_date: patch.end_date,
          status: patch.status || 'active',
          label: patch.label,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TicketType', id }],
    }),
    deleteTicketType: build.mutation({
      query(id) {
        return {
          url: `ticket-types/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'TicketType', id }],
    }),
    getTicketTypeByHoldId: build.query({
      query: (id) => `holdgroups/${id}/ticket-types`,
      providesTags: (result, error, id) => [{ type: 'TicketTypeByHoldID', id }],
    }),
    updateTicketStatus: build.mutation({
      query: ({ eventId, ticketId, status }) => ({
        url: `ticket-types/${ticketId}/status/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'TicketType', id }],
    }),
  }),
});

export const {
  useGetTicketTypeQuery,
  useGetTicketTypesByEventQuery,
  useGetTicketTypesQuery,
  useAddTicketTypeMutation,
  useAddTicketTypeWithOutMapMutation,
  useUpdateTicketTypeMutation,
  useDeleteTicketTypeMutation,
  useGetTicketTypeByHoldIdQuery,
  useUpdateTicketStatusMutation,
  useGetTicketsMutation,
} = ticketTypeApi;
