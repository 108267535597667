import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  if (endpoint === 'getHoldSections') {
    return `seats/holdgroups${url}`;
  }

  return `seats/events/${eventID}/${url}`;
};

const prepareHeaders = (headers, api) => {
  if (api.endpoint === 'updateHold') {
    headers.delete('client_id');
  }
  return headers;
};

export const holdsApi = createApi({
  reducerPath: 'holds',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_SEATS,
    prepareUrl,
    prepareHeaders,
  }),
  tagTypes: ['Holds'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getHolds: build.query({
      query: () => 'holdgroups',
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Holds', id })),
              { type: 'Holds', id: 'LIST' },
            ]
          : [{ type: 'Holds', id: 'LIST' }],
    }),
    getHoldSections: build.query({
      query: (id) => `/${id}/sections`,
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result ? [{ type: 'Holds', id: result.id }] : [],
    }),
    addHold: build.mutation({
      query: (body) => ({
        url: `holdgroups`,
        method: 'POST',
        body: {
          name: body.name,
          user_id: +body.idUser,
          seats: body.seats.map((seat) => {
            const newSeat = {
              row_section: seat.labels.parent,
              seat:
                seat.objectType === 'GeneralAdmissionArea'
                  ? ''
                  : seat.labels.own,
              icon: 'string',
              section:
                seat.objectType === 'GeneralAdmissionArea'
                  ? seat.labels.own
                  : seat.labels.section,
              color: seat.category.color,
            };
            return newSeat;
          }),
        },
      }),
      invalidatesTags: [{ type: 'Holds', id: 'LIST' }],
    }),
    async onQueryStarted(
      { id, ...patch },
      { dispatch, getState, queryFulfilled },
    ) {
      const userID = holdsApi.getState().auth?.user;
      const patchResult = dispatch(
        holdsApi.util.updateQueryData('holdgroups', id, (draft) => {
          Object.assign(draft, patch);
        }),
      );
      try {
        await queryFulfilled;
      } catch {
        patchResult.undo();

        /**
         * Alternatively, on failure you can invalidate the corresponding cache tags
         * to trigger a re-fetch:
         * dispatch(api.util.invalidateTags(['Post']))
         */
      }
    },
    updateHold: build.mutation({
      query: ({ id, body }) => ({
        url: `holdgroups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Holds', id }],
    }),
  }),
});

export const {
  useGetHoldsQuery,
  useGetHoldSectionsQuery,
  useAddHoldMutation,
  useUpdateHoldMutation,
} = holdsApi;
