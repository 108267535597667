import { useParams } from 'react-router';
import useAuth from './useAuth';

export default function useTester({ testerType }) {
  const { user } = useAuth();
  const { event } = useParams();

  const testerTypes = {
    showPrintableSwitch: {
      allowSuperAdmin: false,
      redirectPath: `/dashboard/event/`,
      testers: [
        'angie@boletia.com',
        'david@boletia.com',
        'ian.rosas@boletia.com',
        'marcos@boletia.com',
      ],
    },
    showShareReportCard: {
      allowSuperAdmin: false,
      redirectPath: `/dashboard/event/${event}/stats`,
      testers: [
        'angie@boletia.com',
        'david@boletia.com',
        'ian.rosas@boletia.com',
      ],
    },
    showTicketTypes: {
      allowSuperAdmin: false,
      redirectPath: `/check-in/${event}/attendees`,
      testers: [
        'angie@boletia.com',
        'marcos@boletia.com',
        'david@boletia.com',
        'sergiocamacho+999@boletia.com',
      ],
    },
    showPaymentMethods: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [
        'angie@boletia.com',
        'marcos@boletia.com',
        'david@boletia.com',
        'sergiocamacho+999@boletia.com',
        'diego@boletia.com',
        'ignacio.valdes@boletia.com',
        'silvio@boletia.com',
      ],
    },
    showOldStatistics: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [],
    },
    showWallet: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [
        'sergiocamacho+999@boletia.com',
        'silvio@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
      ],
    },
    showMaxCapacity: {
      allowSuperAdmin: false,
      redirectPath: '/dashboard/events',
      testers: [
        'jhon.quirama@boletia.com',
        'angie@boletia.com',
        'marcos@boletia.com',
        'edwin.garcia@boletia.com',
        'ian.rosas@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
      ],
    },
  };

  const isTester = testerTypes[testerType]?.allowSuperAdmin
    ? testerTypes[testerType]?.testers.includes(user?.email) ||
      !!user?.super_admin
    : testerTypes[testerType]?.testers.includes(user?.email);

  const { redirectPath } = testerTypes[testerType] || {};

  return { isTester, redirectPath };
}
